import React, { useRef } from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import Footer from '../../components/Footer'
import Video from '../../components/Video'
import Lightbox from '../../components/Lightbox'
import './index.css'

const Event = ({ pageContext }) => {

    const dktEvent = pageContext.dktEvent;
    const images = dktEvent.images;
    const lightboxRef = useRef()

    const onClickItem = (e, index) => {
        e.preventDefault()
        lightboxRef.current.setIndex(index)
    }
    
    return (
        <Layout noFooter={true} background={dktEvent.imageFile.childImageSharp.gatsbyImageData}>
            <Seo title={dktEvent.title} meta={[
                {
                    property:'og:image',
                    content: dktEvent.imageFile.childImageSharp.original.src
                }
            ]}/>

            <h1 className="relative block mt-5 mb-10 text-2xl text-center text-red-700 md:text-5xl" 
                style={{zIndex:100}}>{dktEvent.title}</h1>


            <div style={{ position:'relative', minHeight:'40vh', width:'100%' }}>

                {dktEvent.videoUrl
                    ? <Video pageTitle={dktEvent.pageTitle} videoUrl={dktEvent.videoUrl}/>
                    : <GatsbyImage image={dktEvent.imageFile.childImageSharp.gatsbyImageData} 
                        className="event-featured-image" 
                        style={{ position:'sticky', objectFit:'contain', objectPosition:'center bottom', top:0, left:0, right:0, height:'70vh' }} 
                        quality="100"
                        alt="Featured Image"/>
                }

                
                <div className="mx-auto" style={{backgroundColor: (dktEvent.videoUrl? 'none':'black')}}>

                    {!dktEvent.videoUrl ?
                    <div className="relative flex items-center justify-center" style={{ zIndex: 200 }}>
                        <i className="arrow-scroll-indicator"></i>
                    </div>:null
                    }
                    
                    <div className="relative mx-auto overflow-hidden my-container" >
                        <div className="grid items-center content-center grid-cols-2 gap-1 p-5 event-gallery-grid md:grid-cols-3 lg:grid-cols-4 place-items-center">
                        {images && images.map((image, index) => (
                            <button type="button" key={index} onClick={ e => onClickItem(e, index) } className="pointer" style={{ height:'200px' }}>
                                <GatsbyImage key={image.postId} 
                                    image={image.urls.imageFile.childImageSharp.gatsbyImageData} className="" 
                                    style={{width:'100%', height:'100%'}} 
                                    alt="Event Photo"/>
                            </button>
                        ))}
                        </div>
                    </div>

                    <Footer noIconsInvert={dktEvent.videoUrl? false : true}/>

                    <Lightbox ref={lightboxRef} images={images}/>
                    
                </div>

            </div>

            

        </Layout>
    )
}

export default Event;