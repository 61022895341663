import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MdClose, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const Lightbox = forwardRef(({ images }, ref) => {

    const [visible, setVisible] = useState(false)
    const [index, setIndex] = useState(0)
        
    useImperativeHandle(ref, () => ({
        show: () => setVisible(true),
        hide: () => setVisible(false),
        setIndex: (i) => {
            setIndex(i)
            setVisible(true)
        }
    }))

    const onClickLightboxBackground = (e) => {
        e.preventDefault()
        if (e.target === e.currentTarget) {
            setVisible(false)
        }
    }

    const onNext = (e,index) => {
        e.preventDefault()
        index = index >= images.length - 1 ? 0 : index + 1;
        setIndex(index)
    }

    const onPrev = (e,index) => {
        e.preventDefault()
        index = index === 0? images.length - 1 : index - 1;
        setIndex(index);
    }

    

    return (
        <>
        {visible?
        <div onClick={(e) => onClickLightboxBackground(e)} ref={ref} 
                className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-80 backdrop-blur-md"
                style={{zIndex:999999999}}>
            {images.map((image, idx) => {
                if (idx === index) {
                    return (
                    <div key={image.id} style={{maxHeight:'100%', maxWidth:'90%', width:'600px', height:'460px'}} className="relative flex">
                        <div className="flex border-4 border-white rounded">
                            <GatsbyImage image={image.urls.imageFile.childImageSharp.gatsbyImageData} alt={`Photo ${idx + 1} Large Size`}/>
                        </div>

                        <button onClick={(e) => onPrev(e, idx)} 
                            className="absolute left-0 w-10 h-32 transform -translate-y-1/2 rounded-l-md top-1/2 md:bg-white md:w-10 md:h-24 md:right-full md:left-auto">
                                <MdKeyboardArrowLeft className="w-full h-full text-yellow-500 md:text-gray-400 text-opacity-80 "/>
                        </button>
                        <button onClick={(e) => onNext(e, idx)} 
                            className="absolute right-0 w-10 h-32 transform -translate-y-1/2 rounded-r-md top-1/2 md:bg-white md:w-10 md:h-24 md:left-full md:right-auto">
                                <MdKeyboardArrowRight className="w-full h-full text-yellow-500 md:text-gray-400 text-opacity-80"/>
                        </button>

                        <button onClick={() => setVisible(false)} className="absolute top-0 right-0 bg-white rounded-full w-7 h-7" style={{transform:'translateY(-50%) translateX(50%)'}}>
                            <MdClose className="w-full h-full text-gray-400" />
                        </button>
                    </div>)
                }
                return null
            })}
        </div>
        :null}
        </>
    )
})

export default Lightbox